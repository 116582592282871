<template>
  <div class="text-center">
    <v-btn
      v-if="status == 'READY'"
      target="_blank"
      :href="
        'https://www.ncbi.nlm.nih.gov/blast/treeview/treeView.cgi?request=page&blastRID=' +
          ncbi_id +
          '&entrezLim=&ex=&exl=&exh=&ns=100'
      "
       x-small
      outlined
      pill
      color="success"
      class="ma-1"

    >
      <v-icon left>
        mdi-family-tree
      </v-icon>
      View Tree
    </v-btn>

    <v-btn
      v-else
      :disabled="dialog"
      @click="dialog = true"
      :loading="dialog"
      x-small
      outlined
      pill
      class="ma-1"
    >
      <v-icon left>
        mdi-family-tree
      </v-icon>
    {{ status }}
      <template v-slot:loader>
          <v-icon small light left >mdi-loading mdi-spin</v-icon>
        {{ status }}
      </template>
    </v-btn>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      status: "",
      ncbi_id: null,
    };
  },
  props: {
    asv: Object,
    api: String,
  },
  created(){
      this.status = 'Blast '+ this.asv.header + '@NCBI'

  },
  watch: {
    dialog(val) {
      if (!val) return;

      this.sendASVToNCBI();

      // setTimeout(() => (this.dialog = false), 4000)
    },
  },
  methods: {
    async getNCBIStatus() {
      this.axios
        .get(
          "https://blast.ncbi.nlm.nih.gov/blast/Blast.cgi?CMD=Get&FORMAT_OBJECT=SearchInfo&RID=" +
            this.ncbi_id
        )
        .then((res) => {
          this.status = res.data.split("  Status=")[1].split("\n")[0];
          if (this.status == "READY") {
            console.log("ready!");
          } else {
            setTimeout(() => this.getNCBIStatus(), 2000);
          }
        });
    },

    async sendASVToNCBI() {
        this.status = "submitting";
      let data = { header: this.asv.header, sequence: this.asv.sequence };
      this.axios.post(this.api + "/submitASV", data).then((res) => {
        this.status = "submitted";
        this.ncbi_id = res.data;
        this.asv.ncbi_id = res.data;

        //   this.getNCBIStatus()
        setTimeout(() => this.getNCBIStatus(), 5000);

        //   setTimeout(() => (this.status = "ready"), 5000);
      });
    },
  },
};
</script>

<template>
  <v-container fluid>
    <v-row class="text-center">
      <v-col cols="12">
        <v-icon size="200">
          mdi-dna mdi-spin
        </v-icon>
      </v-col>
      <v-col class="mb-4">
        <h1 class="font-weight-bold mb-3">
          screening for putative pests...
        </h1>
        <br />
        {{ status }}
        Estimated time: {{ estimatedTime }}s
        <br />
        <p class="subheading font-weight-regular">
          Hold in there. Depending on how many sequences you submitted, this
          could take a few seconds. You submitted
          {{ fileLines }} ASVs.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    fileLines: Number,
    status: String,
  },
  data() {
    return {};
  },
  computed: {
    estimatedTime() {
      return parseInt((this.fileLines / 1000) * 20);
    },
  },
};
</script>

<template>
  <v-alert type="info" prominent dense outlined>
    Disclaimer:
    <li class="ml-3" v-if="NISList.NIS">
      NIS reference database ({{NISList.NIS.length}} entries) last updated: {{ NISList.timestamp }}
    </li>
    <li class="ml-3"  v-if="unwantedList.unwanted">
      Unwanted and notifiable organism list ({{unwantedList.unwanted.length}} entries) is based on MPIs pest register last
      updated: {{ unwantedList.timestamp }}
    </li>
    <li class="ml-3">
      Completeness: We are not responsible for undiscovered/undetected species
    </li>
  </v-alert>
</template>

<script>
export default {
  name: "Disclaimer",
  props: {
    unwantedList: {
      type: Object,
      default: () => {
        return { unwanted: [], timestamp: "NA" };
      },
    },
    NISList: {
      type: Object,
      default: () => {
        return { NIS: [], timestamp: "NA" };
      },
    },
  },
};
</script>

<style scoped></style>

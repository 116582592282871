<template>
  <v-card
    width="100%"
    class="mb-3"
    :disabled="disabled"
    @drop.prevent="onDrop($event)"
    @dragover.prevent="dragover = true"
    @dragenter.prevent="dragover = true"
    @dragleave.prevent="dragover = false"
    :class="{ 'primary lighten-1': dragover }"
  >
    <label :for="'uploadmyfile'+marker">
      <v-container fluid>
        <v-row class="text-center">
          <v-col cols="12">
            <v-icon size="200" color="primary">
              mdi-cloud-upload-outline
            </v-icon>
          </v-col>
          <v-col class="mb-4">
            <h1 class="font-weight-bold mb-3">
              Drop your
              <span class="secondary--text text-uppercase">
                {{marker}}
                <!-- <slot> </slot> -->
              </span>
              fasta file here
            </h1>

            <p class="subheading font-weight-regular">
              Make sure your *.fa or *.fasta file is in
              <a target="_blank" :href="formats[marker]">this format</a>
              <br />
              Various length examples:
              <a
                class="ma-1"
                target="_blank"
                v-for="item in examples[marker]"
                :href="item.path"
                :key="item.label"
                >{{item.label}}</a
              >
              <!-- <a class="ma-1" target="_blank" href="examples/500.fa">500</a>
              <a class="ma-1" target="_blank" href="examples/1000.fa">1000</a> -->
            </p>
          </v-col>
        </v-row>
      </v-container>
    </label>

    <v-card-actions>
      <v-file-input
        accept=".fa*"
        name="f"
        label="Upload FASTA file"
        @change="selectFile"
        hide-details
        prepend-icon="mdi-dna"
        show-size
        dense
      >
      </v-file-input>
      <input type="file" :id="'uploadmyfile'+marker" class='uploadmyfile' @change="clickedFiles" />
    </v-card-actions>

    <!-- <v-fade-transition>
      <v-overlay v-if="disabled" absolute color="#ccc">
        <v-btn>Coming Soon</v-btn>
      </v-overlay>
    </v-fade-transition> -->
  </v-card>
</template>

<script>
export default {
  name: "UploadComponent",
  props: {
    marker: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formats:{
        "18s":"examples/example.fa",
        coi: "examples/coi_example.fa"
      },
      examples: {
        "18s": [
          { label: "100", path: "examples/100.fa" },
          { label: "500", path: "examples/500.fa" },
          { label: "1000", path: "examples/1000.fa" },
        ],
        coi: [
          { label: "100", path: "examples/coi_100_nochimera_ASVs.fa" },
          { label: "500", path: "examples/coi_500_nochimera_ASVs.fa" },
          { label: "1000", path: "examples/coi_1000_nochimera_ASVs.fa" },
        ],
      },
      overlay: false,
      uploadedFile: null,
      dragover: false,
    };
  },
  methods: {
    onDrop(payload) {
      this.uploadedFile = null;
      this.dragover = false;
      if (payload.dataTransfer.files.length == 1) {
        this.uploadedFile = payload.dataTransfer.files[0];
      }
      console.log(this.marker)
      this.$emit("change", { marker: this.marker, file: this.uploadedFile });
    },
    clickedFiles() {
      var src = this.$el.querySelector("#uploadmyfile"+this.marker);
      if (src.files.length == 1) {
        this.uploadedFile = src.files[0];
        console.log(this.marker)
        this.$emit("change", { marker: this.marker, file: this.uploadedFile });
      }
    },
    selectFile(payload) {
      this.$emit("change", { marker: this.marker, file: payload });
    },
  },
};
</script>

<style scoped>
.uploadmyfile {
  display: none;
}
</style>

<template>
  <v-card-text>
    <v-row align="center">
      <v-col class="grow">
        <v-alert
          v-if="dataset.length == 0"
          prominent
          dense
          outlined
          type="success"
        >
          No putative matches with marine non-indigenous species (NIS)
        </v-alert>
        <v-alert v-else type="warning" prominent dense outlined color="purple">
          <v-row align="center">
            <v-col class="grow">
              Found {{ dataset.length }} putative matches with marine
              non-indigenous species (NIS)
            </v-col>
          </v-row>
        </v-alert>

        <v-alert
          v-if="dataset_unwanted.length > 0"
          type="error"
          prominent
          dense
          outlined
        >
          Found {{ dataset_unwanted.length }} putative matches with unwanted and
          notifiable organisms
        </v-alert>
        <v-alert v-else type="success" prominent dense outlined>
          No putative matches with unwanted and notifiable organisms
        </v-alert>
      </v-col>
      <v-col class="shrink">
        <v-btn @click="downloadResults">
          <v-icon left>
            mdi-download
          </v-icon>
          Download
        </v-btn>
      </v-col>
    </v-row>

    <v-container fluid>
      <v-slider
        :value="pctThreshold"
        @change="setPctThreshold"
        min="98"
        step="0.1"
        ticks
        max="100.0"
        label="Minimum % sequence identity match"
        thumb-label="always"
      ></v-slider>
      <v-slider
        :value="lengthThreshold"
        @change="setLengthThreshold"
        min="100"
        step="10"
        ticks
        max="600"
        label="Minimum sequence length (bp)"
        thumb-label="always"
      ></v-slider>
    </v-container>
    <v-tabs
      v-model="tabi"
      background-color="#00549f"
      centered
      dark
      icons-and-text
      v-if="dataset.length != 0"
    >
      <v-tabs-slider></v-tabs-slider>
      <v-tab href="#tab-1">
        List
        <v-icon>mdi-format-list-bulleted</v-icon>
      </v-tab>

      <!-- <v-tab href="#tab-2">
          Plot
          <v-icon>mdi-chart-bar-stacked</v-icon>
        </v-tab> -->

      <v-tab href="#tab-3">
        Table
        <v-icon>mdi-file-table</v-icon>
      </v-tab>
    </v-tabs>
    <v-card v-if="dataset.length != 0">
      <v-tabs-items v-model="tabi">
        <v-tab-item value="tab-1">
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                xl="3"
                lg="3"
                md="6"
                sm="6"
                v-for="(group, val) in datasetGroupedBySpecies"
                :key="val"
              >
                <div
                  :style="
                    NISList.NIS.includes(val) ? 'border: thin solid #9c26af;' : ''
                  "
                >
                  <div
                    :style="
                      unwantedList.unwanted.includes(val)
                        ? 'border: thin solid #f54235;'
                        : ''
                    "
                  >
                    <v-alert
                      outlined
                      :type="unwantedList.unwanted.includes(val) ? 'error' : 'warning'"
                      prominent
                      :color="unwantedList.unwanted.includes(val) ? 'red' : 'purple'"
                      tile
                      class="mb-0"
                    >
                      <v-row align="center">
                        <v-col class="grow">
                          <b class="mx-1">{{ val.split("_")[0] }} </b>
                          <span class="mx-1">{{ val.split("_")[1] }}</span>
                          <v-row align="center" class="pa-2">
                            <v-chip
                              class="ma-1"
                              small
                              outlined
                              label
                              v-for="el in group"
                              :key="val + el.catalogID + el.qseqid"
                              target="_blank"
                              :href="
                                'https://www.ncbi.nlm.nih.gov/nuccore/' +
                                  el.catalogID
                              "
                            >
                              <v-icon left>
                                mdi-information-outline
                              </v-icon>
                              {{ el.catalogID }}
                            </v-chip>

                            <ncbi-upload
                              :asv="selectASV(el)"
                              :api="API_ENDPOINT"
                              v-for="el in group2uniqueASV(group)"
                              :key="val + 'g' + el"
                            >
                              {{ el }}
                            </ncbi-upload>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-alert>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-tab-item>
        <!-- <v-tab-item value="tab-2">
          <v-card flat>
            <Plotly
              v-if="processed"
              :data="data"
              :layout="layout"
              :display-mode-bar="false"
            ></Plotly>
          </v-card>
        </v-tab-item> -->
        <v-tab-item value="tab-3">
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="dataset"
              item-key="sseqid + qseqid"
              dense
              multi-sort
            >
            </v-data-table>
          </v-card-text>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-card-text>
</template>

<script>
import NcbiUpload from "../components/NcbiUpload.vue";
import { Parser } from "json2csv";

export default {
  props: {
    unwantedList: {
      type: Object,
      default: () => { return {unwanted:[], timestamp: 'NA'}}
    },
    NISList: {
      type: Object,
      default: () => { return {NIS:[], timestamp: 'NA'}}
    },
    res: Array,
    API_ENDPOINT: String,
    parsed_file: Array,
    marker: String,
  },
  created() {
    this.lengthThreshold = this.defaultThresholds[this.marker];
  },
  data() {
    return {
      tabi: null,
      pctThreshold: 99.4,
      defaultThresholds: { "18s": 400, coi: 300 },
      lengthThreshold: 400,

      layout: {
        margin: {
          l: 200,
          r: 20,
          t: 20,
          b: 50,
        },
      },
    };
  },
  components: {
    NcbiUpload,
  },
  computed: {
    dataset() {
      return this.res
        .filter((el) => el.pident >= this.pctThreshold)
        .filter((el) => el.length >= this.lengthThreshold) // 100-600
        .filter((el) => this.NISList.NIS.includes(el.species));
    },
    dataset_unwanted() {
      return this.res
        .filter((el) => el.pident >= this.pctThreshold)
        .filter((el) => el.length >= this.lengthThreshold) // 100-600
        .filter((el) => this.unwantedList.unwanted.includes(el.species));
    },

    // sampleLabels() {
    //   return this.dataset.map((e) => e.qseqid);
    // },
    // yLabels() {
    //   return this.dataset.map((e) => e.sseqid);
    // },
    // data: function() {
    //   return [
    //     {
    //       z: this.dataset.map((avr) => avr.bitscore),
    //       x: this.sampleLabels,
    //       y: this.yLabels,
    //       type: "heatmap",
    //       hoverongaps: false,
    //     },
    //   ];
    // },

    headers() {
      let headers = [];
      if (this.dataset.length > 0) {
        headers = Object.keys(this.dataset[0])
          .filter(
            (key) => !["type", "colour", "catalogID", "species"].includes(key)
          )
          .map((key) => {
            return { text: key, value: key };
          });
      }
      return headers;
    },

    datasetGroupedBySpecies() {
      let grouped = {};
      // let species = [...new Set(this.dataset.map((el) => el.species))];

      for (let i = 0; i < this.dataset.length; i++) {
        if (!grouped[this.dataset[i].species]) {
          grouped[this.dataset[i].species] = [];
        }
        grouped[this.dataset[i].species].push(this.dataset[i]);
      }
      return grouped;
    },
  },
  methods: {
    selectASV(id) {
      let ret = this.parsed_file.find((el) => el.header == id);
      return ret;
    },

    group2uniqueASV(group) {
      return [...new Set(group.map((el) => el.qseqid))];
    },

    setPctThreshold(payload) {
      this.pctThreshold = payload;
    },
    setLengthThreshold(payload) {
      this.lengthThreshold = payload;
    },

    downloadResults() {
      try {
        const fields = [
          "qseqid",
          "sseqid",
          "pident",
          "length",
          "mismatch",
          "gapopen qstart",
          "qend",
          "sstart",
          "send",
          "evalue",
          "bitscore",
        ];
        const opts = { fields };
        const parser = new Parser(opts);
        const csv = parser.parse(this.res);
        let csvContent = "data:text/csv;charset=utf-8," + csv;
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "NIS_blast.txt");
        document.body.appendChild(link); // Required for FF
        link.click();
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>

<style></style>

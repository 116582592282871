<template>
  <div>
    <v-tabs
      v-model="tab"
      background-color="secondary"
      centered
      disabled
      dark
      show-arrows
      icons-and-text
      class="align-self-start"
    >
      <v-tabs-slider></v-tabs-slider>
      <v-tab>
        Select File
        <v-icon>mdi-file-find</v-icon>
      </v-tab>

      <v-tab disabled>
        Uploading
        <v-icon>mdi-file-send</v-icon>
      </v-tab>
      <v-tab disabled>
        Scanning
        <v-icon v-if="tab == 2">mdi-lightning-bolt mdi-spin</v-icon>
        <v-icon v-else>mdi-lightning-bolt</v-icon>
      </v-tab>
      <v-tab>
        Explore results
        <v-icon>mdi-arrow-down</v-icon>
      </v-tab>
    </v-tabs>
    <v-container fluid pa-0>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-container fill-height fluid v-if="tab == 0">
            <v-col>
              <v-alert
                class="align-self-start"
                type="info"
                color="primary"
                dark
                outlined
                prominent
                dense
                icon="mdi-barcode"
              >
                Screen your metabarcoding data for
                <li class="ml-3">
                  <a
                    href="https://www.nature.com/articles/s41598-018-34541-1"
                    target="_blank"
                  >
                    New Zealand marine non-indigenous species
                  </a>
                </li>
                <li class="ml-3">
                  Marine organisms
                  <a
                    href="https://pierpestregister.mpi.govt.nz/PestsRegister/ImportCommodity/"
                    target="_blank"
                    >unwanted and notifiable
                  </a>
                  in New Zealand
                </li>
              </v-alert>
              <v-row>
                <v-col cols="12" sm="6">
                  <upload-component @change="selectFile" marker="18s"
                    >18S</upload-component
                  >
                </v-col>
                <v-col cols="12" sm="6">
                  <upload-component @change="selectFile" marker="coi"
                    >COI</upload-component
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <disclaimer :NISList="NISList" :unwantedList="unwantedList" />
                </v-col>
              </v-row>
            </v-col>
          </v-container>
        </v-tab-item>
        <v-tab-item>
          <v-card>
            <v-card-title>
              Uploading..
            </v-card-title>
            <v-card-text>
              Hold in there. Depending on how many sequences you submitted, this
              could take a few seconds.
              <v-progress-linear :value="progress"> </v-progress-linear>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <blasting-tab
            :fileLines="parsed_file.length"
            :status="blast_status"
          ></blasting-tab>
        </v-tab-item>

        <v-tab-item>
          <results-tab
            v-if="tab == 3"
            :NISList="NISList"
            :API_ENDPOINT="API_ENDPOINT"
            :marker="markerSubmitted"
            :unwantedList="unwantedList"
            :res="res"
            :parsed_file="parsed_file"
          >
          </results-tab>

          <v-container fluid>
            <v-row>
              <v-col>
                <disclaimer :NISList="NISList" :unwantedList="unwantedList" />
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </div>
</template>

<script>
import UploadComponent from "../components/UploadComponent.vue";
import Disclaimer from "../components/Disclaimer.vue";
import BlastingTab from "../components/BlastingTab.vue";
import ResultsTab from "../components/ResultsTab.vue";

export default {
  name: "Home",

  components: {
    UploadComponent,
    Disclaimer,
    BlastingTab,
    ResultsTab,
  },

  data() {
    return {
      NISList: {},
      unwantedList: {},
      // API_ENDPOINT: "https://api.max.ac.nz",
      // API_ENDPOINT: "https://pest-alert-tool.azurewebsites.net",
      // API_ENDPOINT: "https://pest-alert-tool.azurewebsites.net/api",
      API_ENDPOINT: process.env.VUE_APP_API_ENDPOINT,
      // API_ENDPOINT: "http://x1e.cawthron.org.nz:8123/api",
      search: "",
      blast_status: "",
      tab: null,
      parsed_file: [],
      // nisFilter: true,
      progress: 0,
      processed: false,
      submitted: false,
      res: [],
      currentFile: null,
      message: "",
      numSpecies: 20,
      numSamples: 40,
      markerSubmitted: null,
    };
  },
  async mounted() {
    console.log(process.env.VUE_APP_API_ENDPOINT);
    this.getNIS();
    this.getUnwanted();
    this.processed = true;
  },
  methods: {
    getColour(asv) {
      let colour = "#ccc";
      let type = "info";
      if (asv.bitscore > 785) {
        colour = "#ff5252";
        type = "error";
      } else if (asv.bitscore > 775) {
        colour = "#8d9fde";
        type = "warning";
      } else if (asv.bitscore > 760) {
        colour = "#1d29be";
        type = "info";
      }
      return [colour, type];
    },
    async getNIS() {
      this.axios.get(this.API_ENDPOINT + "/nis").then((res) => {
        this.NISList = res.data;
      });
    },
    async getUnwanted() {
      this.axios.get(this.API_ENDPOINT + "/unwanted").then((res) => {
        this.unwantedList = res.data;
      });
    },

    selectFile(payload) {
      this.progress = 0;
      this.currentFile = payload.file;
      console.log(payload.marker);

      this.submit(payload.marker);
    },
    upload(marker, file, onUploadProgress) {
      let formData = new FormData();
      let headers = { "Content-Type": "multipart/form-data" };
      formData.append("in_file", file);
      return this.axios.post(this.API_ENDPOINT + "/" + marker, formData, {
        headers: headers,
        timeout: 50000,
        onUploadProgress,
      });
    },
    postProcess(data) {
      let preprocessed = data
        .filter((el) => el.pident > 95)
        .filter((el) => el.length > 100);
      const result = preprocessed.map((el) => {
        el.species = el.sseqid.split(";")[1];
        el.catalogID = el.sseqid.split(";")[0];
        let format = this.getColour(el);
        el.type = format[1];
        el.colour = format[0];
        return el;
      });
      this.res = result;
    },
    progressF(progressEvent) {
      this.progress = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      if (progressEvent.loaded == progressEvent.total) {
        this.tab = 2;
      }
    },
    submit(marker) {
      this.markerSubmitted = marker;
      if (!this.currentFile) {
        this.message = "Please select a file!";
        return;
      } else {
        this.tab = 1;
        // let local = this

        // let txt = []
        this.parsed_file = [];
        let vm = this;
        const reader = new FileReader();
        reader.onload = function(e) {
          const text = e.target.result;
          let lines = text.split("\n");

          let fullstring = [];
          for (let i = 0; i < lines.length; i = i + 2) {
            let k = lines[i].split(">")[1];
            let v = lines[i + 1];
            if (k) {
              if (v) {
                let d = { header: k, sequence: v };
                fullstring.push(d);
              }
            }
          }
          vm.parsed_file = fullstring;
        };
        reader.readAsText(this.currentFile);

        this.upload(marker, this.currentFile, this.progressF).then((res) => {
          console.log(res);
          if (res.data.length) {
            this.postProcess(res.data);
            this.tab = 3;
          } else {
            let task_id = res.data.task_id;
            this.receive_task_results(task_id);
          }
        });
      }
      this.processed = true;
    },
    receive_task_results(task_id) {
      this.axios.get(this.API_ENDPOINT + "/tasks/" + task_id).then((res) => {
        this.blast_status = res.data.task_status;
        if (this.blast_status == "SUCCESS") {
          this.postProcess(res.data.task_result);
          this.tab = 3;
          this.blast_status = "";
        } else {
          setTimeout(() => this.receive_task_results(task_id), 1000);
        }
      });
    },
  },
};
</script>

<template>
  <v-app>
    <v-app-bar app color="primary" dark dense>
      <!-- <v-app-bar-nav-icon> -->
      <v-btn
        text
        href="https://www.biosecurity-toolbox.org.nz/"
        target="_blank"
        class="pa-0 ma-0"
      >
        <v-img
          class="pa-0 ma-0"
          height="40"
          max-width="95"
          contain
          src="@/assets/MBT-Tohu-Reversed-short.png"
        >
        </v-img>
      </v-btn>

      <!-- <v-icon> mdi-toolbox-outline </v-icon> -->
      <!-- </v-app-bar-nav-icon> -->
      <v-toolbar-title class="d-none d-sm-flex">
        Pest Alert Tool
      </v-toolbar-title>
      <v-spacer />

      <v-btn
        text
        href="https://www.cawthron.org.nz"
        target="_blank"
        class=""
      >
        <v-img
          height="40"
          max-width="127"
          contain
          src="@/assets/cawthron_logo.svg"
        >
        </v-img>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>
